<template>

  <div class="my">
    <!-- <van-nav-bar title="我的" /> -->
    <div>
       <img src="../../assets/images/user-bg.png" style="width: 100%;"  alt="" />
       <div class="logout" @click="connetWallet()">
         <div>Connet Wallet</div>
       </div>
      <div  class="df">
        <img src="../../assets/images/roaclogo.png" style="width: 20%;position: absolute;margin-top: -60%;margin-left: 7%;" alt="" />
        <p class="df aic fw7" style="color: #000;font-size: 0.4rem;margin-top: -112%;margin-left: 30%;" @click="handleClipboard(cpaddress, $event)">
          {{ nubsub }}
         </p>
      </div>
    </div>
    <div style="margin-top: 24%;">
      <img src="../../assets/images/userinfo-bg.png"  style="margin-top: -65%;position: absolute;width: 100%;" alt="" />
      <div style="width: 60%;margin-top: -67%;margin-left: 17%;color: #fff;position: absolute;">
        <div style="font-size: 15px;margin-top: 13%;">
          持幣齡
          </div>
          <div style="margin-top: 10%;font-size: 23px;">
            365/D
          </div>
      </div>
      <div  style="position: absolute;width: 80%;">
        <div style="font-size: 13px;margin-top: -59%;color: #fff;margin-left: 50%;">
          鑄幣能：5.23% /ADG
        </div>
        <span style="margin-left: 83%;font-size: 15px;margin-top: -23%;position: absolute;width: 80%;"><van-rate color="#fff"  v-model="userInfo.level" readonly /></span>
      </div>
    </div>
    <div class="df"  style="position: absolute;width: 100%;margin-top: -10%;">
        <div style="font-size: 13px;margin-top: -30%;color: #fff;margin-left: 7%;background-color: #7A2ACD;;height: 0.6rem;width: 40%;border-radius:32px;text-align: center;padding-top: 5%;">
          創建俱樂部
          提升鑄幣能
        </div>
       <div style="font-size: 13px;margin-top: -30%;color: #fff;margin-left: 6%;background-color: #7A2ACD;;height: 0.6rem;width: 40%;border-radius:32px;text-align: center;padding-top: 5%;">
         拾星俱樂部
          VIP客服
       </div>
      </div>
    <div class="df" style="width: 100%;position: absolute;z-index: 3;margin-left: 5%;">
      <div @click="goTo('/all/allOrder')">
        <img src="../../assets/images/order.png" alt="" style="position: absolute;z-index: 0;margin-top: -25%;width: 40%;" />
        <span style="color: black;position: absolute;font-size: 0.5rem;margin-left: 4%;margin-top: -20%;">商城訂單</span>
        <span style="position: absolute;margin-left: 4%;margin-top: -12%;font-size: 0.25rem;">輕鬆下單 暢享好物</span>
      </div>
     <div @click="goTo('/user/bindInviteCode')">
       <img src="../../assets/images/invite.png" alt="" style="position: absolute;z-index: 0;margin-top: -25%;width: 40%;margin-left: 50%;" />
       <span style="color: black;position: absolute;font-size: 0.5rem;margin-left: 53%;margin-top: -20%;">綁定邀請地址</span>
       <span style="position: absolute;margin-left: 53%;margin-top: -12%;font-size: 0.25rem;">开启更多专属福利</span>
     </div>
    </div>

    <div class="df" style="width: 95%;position: absolute;z-index: 3;margin-top: 5%;margin-left: 15%;background-color: #fff;">
      <div @click="goTo('/user/team')">
        <img src="../../assets/images/kualian.png" alt="" style="position: absolute;z-index: 0;margin-left: 8%;" />
        <span style="color: black;position: absolute;font-size: 0.4rem;margin-left: 4%;margin-top: 10%;">團隊成員</span>
      </div>
      <div style="margin-left: 25%;" @click="goTo('/user/invitation')">
        <img src="../../assets/images/game.png" alt="" style="position: absolute;z-index: 0;margin-left: 8%;" />
        <span style="color: black;position: absolute;font-size: 0.4rem;margin-left: 4%;margin-top: 10%;">分享好友</span>
      </div>
      <div style="margin-left: 25%;" @click="goTo('/user/address')">
        <img src="../../assets/images/shop.png" alt="" style="position: absolute;z-index: 0;margin-left: 8%;" />
        <span style="color: black;position: absolute;font-size: 0.4rem;margin-left: 4%;margin-top: 10%;">地址管理</span>
      </div>
    </div>





    <footer></footer>
  </div>
</template>

<script>
  import clipboard from "@/utils/clipboard";
import {
  getUserInfo,
  getBannerClassReq,
  rechargePartner
} from "@/utils/api.js";
export default {
  data() {
    return {
      userInfo: {},
      part: "",
      vip: "",
      nubsub: '',
      cpaddress:'',
    };
  },
  created() {
    // this.connetWallet();
    this.getuser()
  },
  methods: {
    logout() {
      this.$dialog
        .confirm({
          title: "系统提示",
          message: "确定要退出登录吗？",
        })
        .then(() => {
          this.$store.dispatch("user/logout");
          this.$router.push("/login");
        })
        .catch(() => { });
    },
    goTo(to) {
      this.$router.push(to);
    },
    goRecharge() {
      this.$router.push("/user/Recharge1");
    },
    goUpto() {
      this.$router.push("/user/upto");
    },
    huiyuan() {
      rechargePartner({
        money: 299,
        payment_method: 20
      }).then((res) => {
        let divForm = document.getElementsByTagName('divform')
        if (divForm.length) {
          document.body.removeChild(divForm[0])
        }
        const div = document.createElement('divform')
        div.innerHTML = res.data // res.data就是sb支付宝返回给你的form
        document.body.appendChild(div)
        // document.forms[0].setAttribute('target', '_blank') // 加了_blank可能出问题所以我注释了
        document.getElementById('alipaysubmit').submit()
        // if (res.code == 200) {
        //   console.log(res);
        //   this.$toast({
        //     message: "开通成功",
        //     icon: 'success',
        //     className: 'noticeWidth',
        //   });
        // } else {
        //   this.$toast({
        //     message: "开通失败",
        //     icon: 'fail',
        //     className: 'noticeWidth',
        //   });
        // }
      })
    },
    routerlink() {
      this.$router.push("/user/detailed");
    },
    handleClipboard(text, event) {
      clipboard(text, event);
    },
    connetWallet() {

      if (window.ethereum) {
              window.ethereum.enable().then((res) => {
                // alert("当前钱包地址:" + res[0]);
                this.dapp_address = res[0];
              });
            } else {
              alert("请安装MetaMask钱包");
            }


      this.loading = true;
      const param = {
        dapp_address :this.dapp_address
      };
      this.login(param);
    },
    // 登录
    async login(param) {
      await this.$store
        .dispatch("user/login", param)
        .then(() => {
          // this.loading = false;
          // this.$store.dispatch("user/info");
          // this.getuser();
          this.$router.push("/home");
          localStorage.setItem("hometype", "login");

        })
        // .catch(() => {
        //   this.loading = false;
        // });
    },
    // 获取用户信息
    async getuser(param) {
     getUserInfo().then((res) => {
       this.userInfo = res.data.user;
       this.cpaddress = res.data.user.dapp_address
       this.nubsub = res.data.user.dapp_address.substring(0, 3) + "****" + res.data.user.dapp_address.substr(res.data.user
         .dapp_address.length - 4)
       if (res.data.user.level === 1) {
         this.part = "壹星";
       } else if (res.data.user.level == 2) {
         this.part = "貳星";
       } else if (res.data.user.level == 3) {
         this.part = "叁星";
       } else if (res.data.user.level == 4) {
         this.part = "肆星";
       } else if (res.data.user.level == 5) {
         this.part = "伍星";
       } else {
         this.part = "會員";
       }
     });
    },
  },
};
</script>

<style lang="scss" scoped>
.logout {
    // background: #1a90c0;
    // opacity:0.9;
    border-radius: 32px;
    background: #7A2ACD;
    position: absolute;
    margin-top: -60%;

    text-align: center;
    padding: 14px 0;
    width: 35%;
    margin-left: 60%;
    div {
      color: #fff;
      font-size: 28px;
      font-weight: 700;

    }
  }

.my {
  background-color:  #F4F5F7;;
  height: 100%;
  overflow-x: hidden;
  touch-action: pan-y;
  .banner {
    width: 100%;
    height: 210px;
    padding: 30px 20px;


    // background: linear-gradient(
    //   180deg,
    //   rgba(55, 158, 0, 0.1) 0%,
    //   rgba(55, 159, 0, 0) 100%
    // );
    .msg {
      .avatar {
        margin-top: 25px;
        margin-left: 25px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      .part {
        display: inline-block;
        height: 40px;
        background: #1a90c0;
        border-radius: 30px;
        color: #fff;
        font-weight: 400;
        font-size: 28px;
        line-height: 30px;
        padding: 5px 15px;
        box-sizing: border-box;
        margin-left: 20px;
      }
    }

    .vip {
      width: 590px;
      height: 1px;
      border-radius: 10px;
      background-color: #000000;
      padding: 95px 30px 0px;
      margin: auto;
      margin-top: -20px;

      .vip-box {
        margin-top: -70px;
        margin-left: 30px;
        font-size: 34px;
        font-weight: bolder;
        text-align: center;
      }
    }

    .bord {
      width: 690px;
      height: 180px;
      border-radius: 10px;
      background-color: #379f00;
      padding: 55px 30px 10px;
      margin: auto;
      margin-top: -10px;

      .btns {
        .charge {
          width: 240px;
          height: 72px;
          background: #fff;
          color: #379f00;
          border-radius: 189px;
          position: relative;

          img {
            transform: rotate(135deg);
            margin-right: 10px;
          }
        }

        .upto {
          width: 240px;
          height: 72px;
          background: #379f00;
          color: #fff;
          border-radius: 189px;
          border: 1px solid #fff;
          font-size: 28px;

          img {
            transform: rotate(45deg);
            margin-right: 10px;
          }
        }

        .uptos {
          width: 150px;
          height: 72px;
          background: #379f00;
          color: #fff;
          border-radius: 189px;
          margin-left: 10px;
          border: 1px solid #fff;
          font-size: 28px;

          img {
            transform: rotate(45deg);
            margin-right: 10px;
          }
        }

      }
    }
  }

  .content {
    color: rgba(47, 117, 52, 1);
    position: absolute;
    left: 30px;
    top: 190px;
    width: 690px;
    height: 200px;
    opacity: 1;
    border-radius: 30px;
    background: url("../../assets/images/xg/bg.png");

    >div {
      p {
        margin-top: 20px;
      }

      .van-button {
        margin-top: 10px;
        border-radius: 30px;
        width: 120px;
        height: 50px;
      }
    }
  }

  .order {
    background: #fff;
    width: 690px;
    margin: 0 auto;

    img {
      width: 48px;
      height: 48px;
    }

    p {
      font-size: 26px;
      margin-top: 20px;
      font-weight: 700;
    }
  }

  .f2 {
    background: #f9f9f9;
    width: 690px;
    margin: 0 auto;
    border-radius: 10px;

    .myorder {
      height: 200px;
      border-radius: 10px;
      background: #f9f9f9;

      .dai {
        width: 630px;

        img {
          width: 48px;
          height: 48px;
        }

        p {
          margin-top: 20px;
          font-weight: 700;
        }
      }
    }

    .ing {
      height: 140px;
      opacity: 0.6;
      border-radius: 20px;
      background: #fff;

      img {
        width: 90px;
        height: 90px;
        margin-left: 30px;
      }
    }
  }

  .list {
    padding: 0 30px;
    margin: 0 auto 80px;
    width: 694px;

    border-radius: 20px;
    background: rgba(255, 255, 255, 1);

    .item {
      border-bottom: 0px solid #949BA5;
      padding: 30px 0;
    }

    img {
      width: 48px;
      height: 48px;
    }

    .van-icon {
      font-size: 16px;
    }
  }

  footer {
    height: 100px;
  }
}
</style>
<style lang="scss">
.noticeWidth {
  width: 30% !important;
  font-size: 32px !important;
}

.van-dialog {
  width: 50% !important;
}

.van-dialog__header {
  font-size: 32px !important;
}

.van-dialog__message--has-title {
  font-size: 32px !important;
}

.van-button__text {
  font-size: 32px !important;
}

.van-dialog__message--has-title {
  padding-top: 32px !important;
}

.van-button--large {
  height: 80px !important;
}
</style>
